<template>
    <div class="accounts-setting">
      <div class="page-header">
        <h1>{{ $t('Settings')}} > 雲端數據備份 / 匯入 / 匯出</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>雲端數據備份 / 匯入 / 匯出</h2>
        </div>
        <div class="message">
            {{ 'All of the data had been backup on cloud server. You can download the backup everytime.' }}
        </div>
        <div class="main-account">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{ $t('Download Backup')}}</span>
            </div>
            <a :href=" apiUrl  + '/backup'" class="btn btn-primary">Download</a>
          </el-card>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>匯入餐單數據</span>
            </div>
            <a href="#" class="btn btn-primary">上傳</a>
            <div class="el-upload__tip">只能上傳csv/xlsx文件，且不超過1000MB</div>
          </el-card>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>匯出餐單數據</span>
            </div>
            <a href="#" class="btn btn-primary">上傳</a>
            <div class="el-upload__tip">只能上傳csv/xlsx文件，且不超過1000MB</div>
          </el-card>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    name: 'AccountSettings',
    data(){
      return {
        activeName: '',
      }
    },
    computed: mapState({
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  .main-account{
    width: 98%;
    margin-left: 20px;
  }
  
  .account{
    display: flex;
    .account-email{
      flex: 1;
    }
  }
  
  .account-acions{
    .action-link{
      margin-right: 10px;
    }
  }
  .box-card-header{
    display: flex;
    .card-title{
      flex: 1;
    }
  }
  .message{
    margin-left: 30px;
    margin-bottom: 10px;
  }
  .admin-accounts{
    margin-top: 50px;
    margin-bottom: 20px;
  }
  </style>
  